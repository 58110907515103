import { MutationCache, QueryCache, QueryClient, QueryClientProvider, } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { isAxiosError } from "axios";
import { StatusCodes, getReasonPhrase } from "http-status-codes";
import React, { Suspense, lazy, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isApiException, isApiValidationError } from "@ce/helpers/types";
import { useCurrentLoginMethod } from "@ce/hooks/useCurrentLoginMethod";
import { NotificationType } from "@ce/types/notification.interfaces";
import { NotificationContext } from "./Notifications/NotificationContext";
const LoginModal = lazy(() => import("@ce/components/login/LoginModal"));
const SessionExpiredModal = lazy(() => import("@ce/components/login/SessionExpiredModal"));
const isFinalError = (error) => [StatusCodes.UNAUTHORIZED, StatusCodes.BAD_REQUEST, StatusCodes.NOT_FOUND].includes(error.response?.status);
/**
 * Determine if React Query should retry after failed request
 * if the error is an AxiosError with the status Unauthorized
 * (401) then never retry
 * @param error Possible AxiosError
 * @returns Boolean to indicate if query should be retried
 */
const retryQuery = (error) => {
    return !(isAxiosError(error) && isFinalError(error));
};
function mutationWasUnhandled(mutation) {
    return (
    // Error is unhandled if:
    // - There was no mutation.
    !mutation ||
        // - Mutations didn't have an onError handler.
        !mutation.options.onError || // - There was a handler, but it didn't handle bad request errors.
        mutation.meta?.catchErrors);
}
const ReactQueryWrapper = ({ children }) => {
    const { addNotification } = useContext(NotificationContext);
    const [showLogin, setShowLogin] = useState(false);
    const { t } = useTranslation("", { useSuspense: false });
    const loginMethod = useCurrentLoginMethod();
    const handleOnSuccessLoginModal = () => {
        setShowLogin(false);
        queryClient.refetchQueries();
    };
    const queryClient = useMemo(() => {
        /**
         * Check if query error is AxiosError and if the status is
         * Unauthorized (401) and show Login Modal
         * @param error Possible AxiosError
         */
        const handleStatus = (error, mutation) => {
            const statusCode = error.response?.status;
            switch (statusCode) {
                case StatusCodes.UNAUTHORIZED:
                    setShowLogin(true);
                    break;
                case StatusCodes.BAD_REQUEST:
                    if (mutationWasUnhandled(mutation)) {
                        if (isApiValidationError(error)) {
                            const apiError = error.response?.data;
                            let title = t("Errors");
                            let message = t("ErrorTitle");
                            if (apiError) {
                                title = t("ErrorTitle");
                                message = React.createElement(ApiValidationErrors, { error: apiError });
                            }
                            addNotification({
                                title,
                                message,
                                type: NotificationType.Error,
                            });
                        }
                        else {
                            addNotification({
                                title: error.message,
                                message: error.response?.data,
                                type: NotificationType.Error,
                                autohide: false,
                            });
                        }
                    }
                    break;
                default:
                    if (isApiException(error) && mutationWasUnhandled(mutation)) {
                        const message = error.response?.data.Message;
                        addNotification({
                            title: t("Error"),
                            message: message || t("ErrorTitle"),
                            type: NotificationType.Error,
                        });
                    }
                    break;
            }
        };
        const handleError = (error, _variables, _context, mutation) => {
            if (!isAxiosError(error) || (!!_variables && _variables.meta?.hideErrorToast))
                return;
            handleStatus(error, mutation);
        };
        const defaultQueryClientConfig = {
            mutationCache: new MutationCache({
                onError: handleError,
            }),
            queryCache: new QueryCache({
                onError: handleError,
            }),
            defaultOptions: {
                queries: {
                    // ✅ globally default to 10 seconds
                    staleTime: 1000 * 10,
                    refetchOnWindowFocus: true,
                    retry: (failureCount, error) => retryQuery(error),
                    onError: handleError,
                    onSuccess: () => setShowLogin(false),
                },
                // mutations: { onError: handleError },
            },
        };
        return new QueryClient(defaultQueryClientConfig);
    }, [addNotification, t]);
    return (React.createElement(QueryClientProvider, { client: queryClient },
        children,
        React.createElement(Suspense, { fallback: null }, loginMethod === "PASSWORD" ? (React.createElement(LoginModal, { show: showLogin, onSuccess: handleOnSuccessLoginModal })) : (React.createElement(SessionExpiredModal, { show: showLogin }))),
        React.createElement(ReactQueryDevtools, null)));
};
const ApiValidationErrors = ({ error }) => {
    return (React.createElement("div", null,
        React.createElement("strong", null, error.Message),
        error.ValidationErrors &&
            Object.entries(error.ValidationErrors).map(([key, validations]) => (React.createElement("div", { key: key },
                React.createElement("strong", null,
                    key,
                    ":"),
                React.createElement("ul", null, validations.map((validation, index) => (React.createElement("li", { key: `${key}-${index}` }, validation))))))),
        React.createElement("div", { className: "small mt-3" },
            "(",
            error.StatusCode,
            ": ",
            getReasonPhrase(error.StatusCode),
            ")")));
};
export default ReactQueryWrapper;
