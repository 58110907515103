/* eslint-disable import/no-duplicates */
import { hashKey } from "@tanstack/react-query";
import { isSameDay as fnsIsSameDay, isToday as fnsIsTodayDate, format, isBefore, isPast, parseISO, roundToNearestMinutes, } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { groupBy, isString } from "lodash-es";
// Keep cache of loaded locales for date-fns.
const locales = new Map([["en", enUS]]);
await setLocale(getUserLocale());
await setLocale(getUserLanguage());
export function getUserLocale() {
    return navigator.language || "en-US";
}
export function getUserLanguage() {
    return getUserLocale().split("-")[0];
}
const UTCRegex = /[+-]?\d{2}:\d{2}/;
// Add a locale dynamically when language changes.
export async function setLocale(localeKey) {
    if (locales.has(localeKey))
        return;
    try {
        const locale = await import(`date-fns/locale/${localeKey === "en" ? "en-US" : localeKey}/index.js`);
        locales.set(localeKey, locale);
    }
    catch {
        // eslint-disable-next-line no-console
        console.warn(`Couldn't load locale ${localeKey}`);
    }
}
export function getLocaleWithoutFallback(locale) {
    return locales.get(locale);
}
export function getLocale(locale) {
    return locales.get(locale) ?? enUS;
}
const dateTimeFormatCache = new Map();
/**
 * Every time toLocaleString is called, it has to perform a search in a big database of localization strings,
 * which is potentially inefficient. When the method is called many times with the same arguments,
 * it is better to create a Intl.DateTimeFormat object and use its format() method,
 * because a DateTimeFormat object remembers the arguments passed to it and may decide to cache a slice of the database,
 * so future format calls can search for localization strings within a more constrained context.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 */
export function getDateTimeFormatter(locale, options) {
    const key = hashKey([locale, options]);
    return (dateTimeFormatCache.get(key) ??
        dateTimeFormatCache.set(key, new Intl.DateTimeFormat(locale, options)).get(key));
}
export function createDateTimeOffset(date) {
    return encodeURIComponent(format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
}
export function formatToDateTimeOffset(date) {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}
export const formatDate = (date, localeCode, formatStyle = "PPpp") => {
    const locale = getLocale(localeCode);
    return format(date, formatStyle, { locale });
};
export const isValidStringDate = (date) => {
    return !Number.isNaN(new Date(date).getDate());
};
export const getListNamesOfDays = (locale = "en-US", weekday = "long") => {
    const format = new Intl.DateTimeFormat(locale, { weekday }).format;
    return [
        { value: "0", label: format(new Date(Date.UTC(2022, 7, 14))) },
        { value: "1", label: format(new Date(Date.UTC(2022, 7, 8))) },
        { value: "2", label: format(new Date(Date.UTC(2022, 7, 9))) },
        { value: "3", label: format(new Date(Date.UTC(2022, 7, 10))) },
        { value: "4", label: format(new Date(Date.UTC(2022, 7, 11))) },
        { value: "5", label: format(new Date(Date.UTC(2022, 7, 12))) },
        { value: "6", label: format(new Date(Date.UTC(2022, 7, 13))) },
    ];
};
export const roundToNearestWholeHour = (date) => {
    return roundToNearestMinutes(date);
};
export const isToday = (date) => {
    return fnsIsTodayDate(date);
};
export const isSameDay = (firstDate, secondDate) => {
    return fnsIsSameDay(firstDate, secondDate);
};
export const isTimeInPast = (timestamp) => {
    const parsedTimestamp = parseISO(timestamp);
    return isPast(parsedTimestamp);
};
export const isTimeBefore = (timestampBefore, timestampLimit) => {
    if (!isValidStringDate(timestampBefore) || !isValidStringDate(timestampLimit)) {
        return false;
    }
    return isBefore(new Date(timestampBefore), new Date(timestampLimit));
};
export const getOffsetInMinutes = (timeZone = "UTC", date = new Date()) => {
    const utcDate = new Date(getDateTimeFormatter("en-US", {
        timeZone: "UTC",
        dateStyle: "short",
        timeStyle: "medium",
    }).format(date));
    const tzDate = new Date(getDateTimeFormatter("en-US", { timeZone, dateStyle: "short", timeStyle: "medium" }).format(date));
    return (tzDate.getTime() - utcDate.getTime()) / 60000;
};
export const getOffsetInHours = (offset) => {
    if (offset === null) {
        return "";
    }
    if (offset === 0) {
        return "Z";
    }
    let hours = Math.floor(offset / 60);
    const minutes = Math.abs(offset % 60);
    const sign = hours >= 0 ? "+" : "-";
    hours = Math.abs(hours);
    const hoursString = hours.toString().padStart(2, "0");
    return `${sign}${hoursString}:${minutes || "00"}`;
};
export const getOffsetHumanReadable = (offset) => {
    if (offset === 0) {
        return "GMT";
    }
    return `GMT ${getOffsetInHours(offset)}`;
};
export const convertFromLocalToDestinationTimezone = (timestamp, timezone) => {
    if (!isValidStringDate(timestamp) || !timezone) {
        return "";
    }
    const fromInput = new Date(timestamp);
    const offset = getOffsetInHours(getOffsetInMinutes(timezone, fromInput));
    if (!offset) {
        return "";
    }
    const temporary = new Date(timestamp);
    temporary.setUTCHours(fromInput.getHours());
    temporary.setUTCDate(fromInput.getDate());
    temporary.setUTCMonth(fromInput.getMonth());
    return new Date(temporary).toISOString().replace("Z", offset);
};
export const convertFromDestinationToLocalTimezone = (timestamp) => {
    if (!isValidStringDate(timestamp)) {
        return "";
    }
    const localOffset = getLocalOffset();
    return replaceTimezoneOffset(timestamp, localOffset);
};
export const replaceTimezoneOffset = (timestamp, offset) => {
    if (!isValidStringDate(timestamp)) {
        return "";
    }
    const regex = /(([+-]\d{2}:\d{2})|Z)$/; // Matches "+hh:mm", "-hh:mm", or "Z" at the end of the string
    return timestamp.replace(regex, offset);
};
export const getSupportedTimezones = () => {
    const now = new Date();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (("supportedValuesOf" in Intl && Intl.supportedValuesOf("timeZone")) || []).map((timezone) => {
        const [region, ...zone] = timezone.split("/");
        return {
            region,
            zone: zone.join("/"),
            offset: getOffsetHumanReadable(getOffsetInMinutes(timezone, now)),
        };
    });
};
export const getSupportedTimezonesGrouped = () => groupBy(getSupportedTimezones(), "region");
export const getLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getLocalOffset = () => getOffsetInHours(getOffsetInMinutes(getLocalTimezone()));
export function getMaxMinutes(startDate, endDate) {
    return startDate && endDate && isSameDay(startDate, endDate) ? endDate.getMinutes() : 0;
}
export function getMaxHour(startDate, endDate) {
    return startDate && endDate && isSameDay(startDate, endDate) ? endDate.getHours() : 0;
}
export function getMinMinutes(startDate, endDate) {
    return startDate && endDate && isSameDay(startDate, endDate) ? startDate.getMinutes() : 0;
}
export function getMinHour(startDate, endDate) {
    return startDate && endDate && isSameDay(startDate, endDate) ? startDate.getHours() : 0;
}
export function getTimezoneOffset(date) {
    if (!isString(date))
        return "";
    const [_, timeZone] = date.match(/([+-]\d{2}:?\d{2}\b)$/) || [];
    return `GMT${timeZone}`;
}
export function getTimeZoneOffsetInMinutes(date) {
    // Z or ±hh:mm or ±hhmm
    const tokens = /([+-]\d{2}):?(\d{2})$/.exec(date);
    if (!tokens)
        return 0;
    const hours = Number(tokens[1]);
    const minutes = Number(tokens[2]);
    if (!validateTimezone(hours, minutes)) {
        return Number.NaN;
    }
    const absoluteOffset = Math.abs(hours) * 60 + minutes;
    return hours >= 0 ? absoluteOffset : -absoluteOffset;
}
function validateTimezone(hours, minutes) {
    return -23 <= hours && hours <= 23 && (minutes == null || (0 <= minutes && minutes <= 59));
}
//Returns the date formatted like 2024-06-21T14:16:38.650+02:00
export function formatDateWithTimezone(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    const timezoneOffset = date.getTimezoneOffset();
    const offsetSign = timezoneOffset > 0 ? "-" : "+";
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, "0");
    const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}
export function validateUTCOffset(value) {
    return value.length < 7 && UTCRegex.test(value ?? "");
}
